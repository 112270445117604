.documents {
  margin: 0;
  :global {
    .ant-list-item {
      justify-content: start;
      padding: 6px 0;
      border-block-end: 0 solid transparent;
      .ant-list-item-meta {
        align-items: center;
        max-width: 230px;
      }

      .ant-list-item-meta-avatar {
        margin-inline-end: 10px !important;
      }

      .ant-list-item-meta-title {
        margin-bottom: 0 !important;
      }

      .ant-list-item-meta-description {
        color: #1f1f1f !important;
      }
    }
  }
}
