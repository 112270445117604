.wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;

  > svg {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
  }

  :global {
    .ant-form {
      > *:last-child {
        width: 100%;
      }
    }

    .logo {
      max-width: 240px;
    }
  }
}
