@import 'src/resource/style/variables';

.notes {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 0;
  color: #1f1f1f;
  font-weight: 600;
}

.icon {
  color: $primary-color;
  font-size: 18px;
}

.modal {
  :global {
    .ant-btn-loading-icon {
      margin-inline-end: 2px !important;
    }
  }
}
