.wrapper {
  position: relative;

  > .loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,.4);
  }

  > .menu {
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
    }
  }
}


.search {
  width: calc(100% - 16px);
  padding-left: 16px;
}

.menu {
  :global {
    .ant-menu-title-content .ant-checkbox-wrapper {
      white-space: normal;
    }

    .ant-checkbox-wrapper-disabled {
      .ant-badge-status-text {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  li label span {
    text-transform: capitalize;
  }
}

.checkedAll {
  position: relative;
  opacity: 0.9;
  &::before {
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: transparent;
    content: '';
  }
}

.checkboxWrap {
  position: relative;
  &::before {
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: transparent;
    content: '';
  }
}
