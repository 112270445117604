.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

.inner {
  max-width: 1184px;
  margin: 24px auto 0 auto;
  padding: 24px 20px;
}
