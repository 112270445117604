.phone {
  margin-top: 34px;

  :global {
    .ant-form-item-label {
      font-weight: 600;
    }
  }
}

.modal {
  max-width: 556px;
  :global {
    .ant-modal-content {
      padding: 30px 40px;
    }
  }
}

.text {
  color: #8c8c8c;
  font-size: 12px;
}

.title {
  margin: 0 !important;
}

.btn {
  margin-top: 12px;
}

.back {
  margin-top: 0px;
  margin-bottom: 0;
}
