@import 'src/resource/style/variables';

.wrapper {
  background-color: $bg-color-content;

  :global {
    .ant-form-item-label {
      font-weight: 600;
    }

    .ant-tabs-tabpane {
      min-height: calc(100vh - 200px);
    }
  }

  &.maxWidth {
    max-width: 1230px;
    margin: 0 auto;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 65px;
}
