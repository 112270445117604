@import 'src/resource/style/variables';

.resizeIcon {
  position: absolute;
  top: 0;
  z-index: 3;
  display: none;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 0 !important;
  height: 100%;
  color: #8c8c8c;
  font-size: 36px;
  cursor: col-resize;

  &:hover {
    color: #5e5e5e;
  }

  &.visible {
    display: flex;
  }

  &.left {
    left: -12px;
  }

  &.right {
    right: -12px;
  }

  &.disable {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.shift {
  position: relative;

  &:not(.day) {
    width: 100%;
  }

  &.day {
    overflow: hidden;
    min-width: 5px !important;
  }

  &.absolute {
    &:not(:first-child) {
      position: absolute;
      bottom: 30px;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    padding:7px;
    overflow: hidden;
    background: $bg-color-light;
    border: solid 1px $border-color-light;
    border-radius: 4px;
    cursor: pointer;

    &.active {
      border-color: $primary-color;
      box-shadow: 0 0 0 0.5px $primary-color;
    }

    &.unassigned {
      background: var(--Neutral-Table-BG, #f0f0f0);
      border: 1px dashed var(--Neutral-Placeholder, #bfbfbf);
      &.active {
        border: 1px solid var(--neutral-secondary-text-icons, #8c8c8c);
      }
    }

    &.draft {
      background: var(--Warning-warning-1, #fff7e8);
      border: 1px dashed var(--Warning-warning-4, #fccb6b);
      &.active {
        border: 1px solid var(--Warning-Warning, #faad14);
      }
    }

    .icon {
      align-items: center;
      height: 16px;

      > * {
        &:not(:first-child) {
          margin-left: 4px;
        }
      }

      .circle {
        width: 10px;
        height: 10px;
        margin: 2px;
        background-color: $color-success;
        border-radius: 50%;
        box-shadow: 0 0 0 2px #fff;
      }

      svg {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
    }

    .name,
    .type,
    .time {
      height: 13px;
      margin-top: 0px;
      line-height: 12px;
    }

    .icon,
    .name,
    .type,
    .time {
      flex-wrap: nowrap;
      width: calc(100% - 6px);
      margin-bottom: 0;
      font-size: 12px;
      white-space: nowrap;
    }

    .time {
      :global {
        span {
          margin-right: 3px;
          color: var(--Primary-Primary-green, #61a23a);
        }
      }
    }

    .type {
      height: 13px;
      line-height: 13px;
    }

    &.fake {
      opacity: 0;
    }

    &.long {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.05);
}
