.header {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1340px) {
    display: block;
  }

  .filter {
    :global {
      .ant-form-item {
        margin-bottom: 10px;
      }
    }

    @media (max-width: 1340px) {
      margin-top: 12px;
    }

    label {
      margin-right: 8px;
    }

    > div {
      &:first-child {
        margin-bottom: 10px;
      }
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;

      font-weight: 400;
      font-size: 14px;

      :global {
        .ant-select-selector {
          min-width: 105px;
        }
      }

      > *:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}

.btn {
  box-shadow: none;
}

.btnWrapper {
  min-height: 32px;
}

.modal {
  max-width: 570px;
  width: 100% !important;
}



.modalRow {
  margin-left: 0 !important;
}