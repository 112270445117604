@import "src/resource/style/variables";

.col {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  padding: 0 calc((100% / 24) / 2);

  .hour {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: calc(100% / 23);
    min-width: 40px;
    font-size: 12px;

    &:before {
      position: absolute;
      content: '';
      left: calc(50% - .5px);
      bottom: 0;
      height: 18px;
      width: 0;
      border-left: solid 1px $border-color;
    }
  }
}
