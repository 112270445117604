.wrapper {
  input {
    display: none;
  }

  button {
    width: 24px !important;

    svg {
      color: #000;
      font-size: 13px;
      opacity: 0.45;
    }
  }

  .dayPicker {
    max-width: 90px;
  }

  .value {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 70px;
    height: 32px;
    padding: 0 16px;
    border-top: 1px solid var(--neutral-stroke, #d9d9d9);
    border-bottom: 1px solid var(--neutral-stroke, #d9d9d9);
  }

  .picker {
    width: 80px;
    margin-left: 8px;
  }
}
