.wrapper {
  width: 100%;
  padding-bottom: 16px;
}

.fullWidth {
  width: 100%;
}

.noMarging {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.checkbox {
  margin-bottom: 8px;
}
