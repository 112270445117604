@import 'src/resource/style/variables';

.options {
  position: absolute;
  top: 0;
  left: calc(100% - 16px);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 100%;
  cursor: pointer;

  svg {
    font-size: 14px;
  }
}

.info {
  :global {
    .ant-popconfirm-message-icon {
      display: none;
    }
    .ant-popconfirm-buttons {
      display: none;
    }

    .ant-popover-inner {
      padding: 0;
    }

    .ant-space {
      row-gap: 0px;
    }

    .ant-btn{
      text-align: left;
    }

    .ant-popconfirm-description {
      margin-top: 0;
    }
  }
}
