$column-width: 274px;

.transfer {
  flex-direction: row-reverse;
  :global {
    .ant-transfer-list {
      width: 100%;
      max-width: $column-width;
      height: 240px;
    }

    .ant-transfer-list-header-dropdown {
      display: none;
    }

    .ant-transfer-operation .ant-btn {
      box-shadow: none;
      transform: scale(-1, -1);
    }
  }
}

.title {
  margin-top: 0;
}

.titles {
  display: flex;
  align-items: center;

  .title {
    width: $column-width;
    margin: 0 !important;
    &:last-child {
      margin-left: 40px !important;
    }
  }
}

.wrapper {
  padding-left: 24px;
  padding-top: 20px;
}