@import "src/resource/style/variables";

.loading {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background-color: $bg-color-content;
}

.wrapper {
  border-radius: 8px;
  border: 1px solid var(--Neutral-Stroke, #d9d9d9);
  background: var(--Neutral-neutral-2, #fcfcfc);
  margin: 24px;
  padding: 24px;
}

.input {
  max-width: 540px;
  width: 100%;
  padding-right: 110px;
}

.form {
  display: flex;

  flex-wrap: wrap;
}


.table {
  padding: 24px;
}

