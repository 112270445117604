.item {
  margin-top: 12px;
  padding: 8px;
  background: var(--Neutral-Table-BG, #f0f0f0);
  border: 1.5px dashed var(--Neutral-Placeholder, #bfbfbf);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  &.active {
    border: 1.5px solid var(--neutral-secondary-text-icons, #8c8c8c);
  }

  &.disable {
    opacity: .7;
  }
}

.date,
.row {
  height: 9px;
  margin-top: 3px;
  font-size: 12px;
  line-height: 12px;
  line-height: 9px;
  white-space: nowrap;
}

.row {
  color: var(--Neutral-Primary-text, #1f1f1f);
}

.date {
  display: block;
  padding: 0;
}

.item {
  margin-top: 12px;
  padding: 8px;
  background: var(--Neutral-Table-BG, #f0f0f0);
  border: 1px dashed var(--Neutral-Placeholder, #bfbfbf);
  border-radius: 4px;
}
