@import 'src/resource/style/variables';

.header {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  span:last-child {
    background-color: $bg-color-content;
  }

  span {
    width: 32px;
    height: 32px;
    padding: 9px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;

    svg {
      color: #8c8c8c;
    }

    &:hover {
      background-color: #dbdbdb;
    }
  }
}

.day,
.shift {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: block;
  box-sizing: border-box;
  overflow: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  transform: translate(-999999px, -999999px);
}

.day {
  max-height: 340px;
  padding: 11px 20px 16px;

  .content {
    ul {
      display: block;
      max-height: 237px;
      margin: 4px -20px 2px 0;
      padding: 0 20px;
      overflow: auto;
      list-style-type: none;
      padding-inline-start: 0;

      &.max {
        max-height: 277px;
      }

      li {
        position: relative;
        padding: 0 4px 0 22px;
        overflow: hidden;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.1s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        :global {
          .ant-typography {
            width: calc(100% - 18px);
            font-size: 12px;
          }
        }

        &:hover,
        &.active {
          background: #eff6eb;
          box-shadow: inset 0 0 0 1px $primary-color;
        }

        &:active {
          box-shadow: inset 0 0 4px 0.5px $primary-color;
        }

        &:not(:last-child) {
          margin-bottom: 4px;
        }

        &:before {
          position: absolute;
          top: 7px;
          left: 8px;
          width: 6px;
          height: 6px;
          background-color: var(--point-color);
          border-radius: 50%;
          content: '';
        }
      }
    }

    .btn {
      margin-top: 8px;
      color: $primary-color;
      border: 1px solid $primary-color;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);

      &:hover,
      &:active {
        color: $primary-color;
      }
    }
  }
}

.shift {
  height: 360px;
  padding: 11px 24px 24px;

  .content {
    padding-top: 11px;

    .row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;
      line-height: 22px;

      > :first-child {
        font-weight: 600;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.disable {
  display: none !important;
}

.alert,
.loading {
  margin: 16px 0 16px -6px;
}

.loading {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
}

.hovered {
  background: var(--primary-highlight, #eff6eb);
  border: 1px solid var(--primary-primary-green, #61a23a);
  border-radius: 4px;
}

.time {
  align-items: flex-end;
}