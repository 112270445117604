.wrapper {
  position: absolute;
  top: 64px;
  bottom: 0;
  left: 0;
  z-index: 11;
  width: 224px;
  height: calc(100vh - 64px);
  overflow: auto;
  background: var(--Neutral-White-BG, #fff);
  border-radius: 0 12px 0 0;
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.14);
  transform: translateX(-100%);
  transition: all 0.5s;
  animation: slidein 0.3s ease-in-out 0s 1 normal forwards;
  &.closed {
  animation: slideout 0.3s ease-in-out 0s 1 normal forwards;

  }
}

@keyframes slidein {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}
@keyframes slideout {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.panel {
  height: 38px;
  padding: 10px;
  background: var(--neutral-separator-lines, #f5f7f9);
  border-radius: 12px 12px 0px 0px;
}

.inner {
  padding: 8px 16px;
}

.item {
  margin-top: 12px;
  padding: 8px;
  background: var(--Neutral-Table-BG, #f0f0f0);
  border: 1px dashed var(--Neutral-Placeholder, #bfbfbf);
  border-radius: 4px;
}

.date,
.row {
  height: 9px;
  margin-top: 3px;
  font-size: 12px;
  line-height: 12px;
  line-height: 9px;
  white-space: nowrap;
}

.row {
  color: var(--Neutral-Primary-text, #1f1f1f);
}

.date {
  display: block;
  padding: 0;
}

.status {
  :global {
    .ant-badge-status-dot {
      width: 10px !important;
      height: 10px !important;
    }
  }
}
.icons {
  display: flex;
  align-items: center;
}
.icon {
  margin-left: 4px;
  color: #00000073;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.found {
  padding-top: 14px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}
