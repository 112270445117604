.layout {
  height: 100%;
  margin-bottom: 16px;
  padding: 0px 6px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  h4 {
    margin: 0;
  }
}
