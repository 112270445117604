.btn {
  margin-top: 16px;
  margin-right: 16px;
}

.resetLink {
  margin-top: 16px;
  margin-bottom: 8px;
  display: block;
}

