@import 'src/resource/style/variables';

.day {
  position: relative;
  font-size: 12px;

  :global {
    .ant-badge {
      .ant-badge-status-text {
        font-size: 12px;
      }
    }
  }

  .theeDot {
    padding: 0 0 0 10px;
    color: $neutral-secondary;
    line-height: 10px;
  }
}

.moreInfo {
  gap: 0 !important;
  width: calc(100% - 18px);
  :global {
    .ant-typography {
      font-size: 12px;
    }
  }
}

.item {
  transition: all .3s;
  padding: 0 4px;
  background: transparent;
  border: 1px solid transparent;
  :global {
    .ant-badge {
      width: 100%;
    }
  }
}

.hovered {
  background: var(--primary-highlight, #eff6eb);
  border: 1px solid var(--primary-primary-green, #61a23a);
  border-radius: 4px;
}

.locationRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.textWrapper{
  width: calc(100% - 18px);
}