.title {
  > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 32px;
    font-weight: 700;

    &:not(:first-child) {
      margin-top: 10px;
    }

    h4 {
      margin: 0;
      white-space: nowrap;
    }

    .tabs {
      margin: -2px 0 -2px 16px;
      display: block;
      white-space: nowrap;
    }
  }

  .switch button {
    margin-left: 12px;
  }
}
