.applicants {
  .item {
    margin-bottom: 16px;
    padding: 2px 0px 2px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s;
    border-block-end: 1px solid #d9d9d9 !important;

    &.active {
      background: #eff6eb;
      border: 1px solid #528a31 !important;
    }

    &:hover {
      border: 1px solid #528a31 !important;
    }
  }
  :global {
    .ant-list-item-meta-title {
      font-weight: 400;
      margin: 0 !important;
    }

    .ant-list-footer {
      padding-block: 0;
    }
  }
}

.title {
  margin-top: 12px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.wrapper {
  position: relative;
  padding-bottom: 24px;
}

.btn {
  position: absolute;
  top: -60px;
  right: 0;
}

.requirements {
  ul {
    margin: 8px 0;
    padding-left: 28px;
  }
}

.remove {
  display: block;
  margin: 0 0 0 auto;
}

.modal {
  display: block;
}
