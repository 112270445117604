@import 'src/resource/style/variables';

.swapBtn {
    padding: 4px 6px;
}

.uploadIcon {
    color: #8C8C8C;
}

.swapIcon {
    color: $primary-color;
}