.collapse {
  :global {
    .ant-collapse-expand-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline-start: 0 !important;
      width: 24px;
      height: 24px;
      margin-right: 16px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
      padding-inline-end: 0 !important;
    }
    .ant-collapse-header {
      background: #eff6eb;
      border-radius: 6px !important;
    }

    .ant-collapse-item .ant-collapse-header {
      align-items: center;
      padding: 11px 8px 11px 12px;
      height: 47px;
    }

    .ant-collapse-header-text {
      font-weight: 600;
    }

    .ant-collapse-item {
      // margin-bottom: 12px;
      padding-bottom: 12px;
      .ant-collapse-content-box {
        padding: 0 0 0 56px !important;
        font-size: 14px;
      }
    }

    .ant-table-wrapper {
      margin-top: 8px;

      .ant-table-thead > tr > th {
        &:first-child {
          border-radius: 6px 0 0 6px !important;
        }

        &:last-child {
          border-radius: 0 6px 6px 0 !important;
        }
      }
    }
  }

  // add lines for collapse //

  :global {
    .ant-table-wrapper {
      position: relative;
      &:before,
      &:after {
        position: absolute;
        content: '';
        pointer-events: none;
      }
      &:before {
        top: -7px;
        left: -32px;
        width: 23px;
        height: 32px;
        border-bottom: 1px dashed #d9d9d9;
        border-left: 1px dashed #d9d9d9;
      }

      &:after {
        top: 22px;
        left: -7px;
        width: 0;
        height: 0;
        border-color: transparent transparent transparent #d9d9d9;
        border-style: solid;
        border-width: 3px 0 3px 5px;
      }
    }

    .ant-collapse-item .ant-collapse-item {
      position: relative;
      &:before,
      &:after {
        position: absolute;
        content: '';
        pointer-events: none;
      }

      &::before {
        top: -8px;
        left: -31px;
        width: 1px;
        height: 100%;
        border-left: 1px dashed #d9d9d9;
      }

      &::after {
        top: 23.5px;
        left: -28px;
        width: 20px;
        height: 1px;
        border-top: 1px dashed #d9d9d9;
      }

      &:last-child {
        &::before {
          height: 32px;
        }
      }

      .ant-collapse-header {
        position: relative;

        &::before {
          position: absolute;
          top: 21px;
          left: -6px;
          width: 0;
          height: 0;
          border-color: transparent transparent transparent #d9d9d9;
          border-style: solid;
          border-width: 3px 0 3px 5px;
          content: '';
        }
      }
    }
  }
}
