.wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 24px 20px;

  ul {
    list-style: disc;
    padding-left: 4px;
  }
}
