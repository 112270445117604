.table {
  :global {
    .ant-table-tbody > tr > td {
      padding: 12px;
    }

    .ant-pagination {
      padding-right: 16px;
    }
  }
}

.status {
  text-transform: capitalize;
}

.bullet {
  :global {
    .ant-badge-status-text {
      color: #fff !important;
    }
  }
}

.tooltipe {
  :global {
    .ant-tooltip-inner {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}
