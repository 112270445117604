.wrapper {
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.checkbox {
  margin-bottom: 18px;
  font-weight: 600;
}

.timePopup {
  :global {
    .ant-picker-time-panel-column::after {
      display: none !important;
    }
  }
}
