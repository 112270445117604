.text {
  color: #8c8c8c;
  font-size: 12px;
}

.title {
  margin-top: 0;
  margin-bottom: 0 !important;
}

.btn {
  margin-top: 20px;
  margin-bottom: 16px;
}
