@import 'src/resource/style/variables';

.wrapper {
  position: relative;

  width: 100%;
  padding: 16px;
  background: #eff6eb;
  border: 1px solid #528a31;
  border-radius: 6px;
}

.form {
  max-width: 556px;
  :global {
    font-weight: 600;
  }
}

.editable {
  position: absolute;
  top: 14px;
  right: 10px;
  z-index: 1;

  :global {
    .anticon {
      color: #8c8c8c;
      font-size: 21px;
    }
  }
}

.icon {
  color: $primary-color !important;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}
