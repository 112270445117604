.extra {
  padding: 8px 16px;
  background: #eff6eb;
  border-radius: 8px;
}

.loading {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}