.documents {
  margin: 0;

  :global {
    .ant-list-item {
      padding: 6px 0;
      border-block-end: 0px solid transparent;

      .ant-list-item-meta {
        align-items: center;
      }

      .ant-list-item-meta-avatar {
        margin-inline-end: 10px !important;
      }

      .ant-list-item-meta-title {
        margin-bottom: 0 !important;
      }
    }
  }
}

.wrapper {
  width: 100%;
  max-width: 684px;
}
