@import "src/resource/style/variables";

.wrapper {
  padding: 16px;
  height: 50px;

  &.padding {
    padding: 2px 0 18px;
  }

  .arrowBack {
    font-size: 16px;
    cursor: pointer;
    margin: -12px -8px;
    padding: 12px 8px;
    margin-right: 0;
    color: $neutral-secondary;
  }

  .title {
    margin: 0;
  }

  .icon {
    font-size: 18px;
    color: $neutral-secondary;
    margin-left: 8px;
    cursor: pointer;
  }
}
