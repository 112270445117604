$white: #fff;
$primary-text: #1F1F1F;
$primary-color: #61A23A;
$neutral-separator-lines: #F5F7F9;
$neutral-secondary: #8C8C8C;
$bg-color-content: #F0F0F0;
$bg-color-light: #EFF6EB;
$border-color: #D9D9D9;
$border-color-light: #d9e9d0;
$color-success: #52C41A;
