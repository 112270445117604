.title {
  margin-top: 0;
}

.select {
  width: 100%;
  max-width: 360px;
}

.label {
  margin-bottom: 0;
  :global {
    label {
      font-weight: 400;
    }
  }
}

.link {
  text-decoration: underline !important;
}