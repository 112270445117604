.actionColSelect {
  max-width: 20px;
}

.actionColRight {
  max-width: 40px;
}

.status {
  text-transform: capitalize;
}

.btn {
  padding: 0;
  height: auto;
}