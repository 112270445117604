.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

.inner {
  max-width: 830px;
  margin: 24px auto 0 auto;
  padding: 24px 20px;
}

.hideTabs {
  margin-top: 20px;
  :global {
    .ant-tabs-nav {
      display: none;
      opacity: 0;
    }
  }
}
