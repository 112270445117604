@import 'src/resource/style/variables';

.roles {
  .item {
    margin-bottom: 16px;
    padding: 2px 0px 2px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s;
    border-block-end: 1px solid #d9d9d9 !important;

    &.active {
      background: #eff6eb;
      border: 1px solid #528a31 !important;
    }

    &:hover {
      border: 1px solid #528a31 !important;
    }
  }

  .btn {
    padding-right: 4px;
    padding-left: 4px;
  }

  .icon {
    color: #8c8c8c;
  }

  :global {
    .ant-list-item-meta-title {
      font-weight: 400;
      margin-bottom: 0 !important;
    }
  }
}

.createBtn {
  color: $primary-color;
  border: 1px solid $primary-color;
}

.wrapper {
  width: 100%;
  max-width: 1144px;
}
