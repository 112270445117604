.menu {
  :global {
    .ant-menu-title-content .ant-checkbox-wrapper {
      white-space: normal;
    }
  }

  li label span {
    text-transform: capitalize;
  }
}
