.wrapper {
  .header {
    padding: 16px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;

    :global {
      .ant-pagination {
        margin: 16px;
      }
    }
  }
}

.blocked {
  position: absolute;
  top: 58px;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 58px);
  font-weight: bold;
  font-size: 16px;
  backdrop-filter: blur(3px);
}
