@import "src/resource/style/variables";

.calendar {
  :global {
    .plus-wrapper {
      display: none;
      justify-content: center;
      align-items: center;
      min-height: 70px;

      svg {
        font-size: 40px;
        color: $primary-color;
      }
    }

    .ant-picker-cell {
      cursor: pointer;

      &:hover {
        .plus-wrapper {
          display: flex;
        }
      }
    }

    .ant-picker-calendar-date-content {
      .ant-badge-status-text {
        margin-inline-start: 12px;
      }
    }

    .ant-picker-calendar-date {
      padding: 4px 6px 0 !important;
    }
  }
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1px);
  z-index: 2;
}

