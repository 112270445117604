.wrapper {
  max-width: 556px;
  margin-bottom: 24px;
  padding: 24px 24px 50px 24px;
  background: #fcfcfc;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  :global {
    .ant-form-item-label {
      font-weight: 600;
    }
  }
}

.inner {
  margin-top: 12px;
}

.text {
  color: #8c8c8c;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 102px;
}

.upload {
  width: 100% !important;
  color: #8c8c8c;
  :global {
    .ant-upload,
    .ant-upload-list-item-container {
      width: 100% !important;
    }
    .ant-typography {
      color: #8c8c8c;
    }
    .anticon {
      font-size: 24px;
    }
  }
}

.square {
  width: 104px;
}

.rectangle {
  width: 178px;
}

.marginTooltip {
  margin-left: 6px;
}
