.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 130px;
}

.container {
  width: 100%;
}

.remove {
  color: #8c8c8c;
  font-size: 20px;
}

.add {
  font-size: 20px;
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
  :global {
    .ant-form-item {
      margin-bottom: 0px;
    }

    .ant-space:last-child .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.firstItem {
  margin-top: 24px;
}

.collapse {
  padding-bottom: 24px;
  :global {
    .ant-form-item-explain-error {
      position: absolute;
      top: 100%;
    }
  }
}
