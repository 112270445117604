@import 'src/resource/style/variables';

.variant {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 8px;
  overflow: hidden;
  color: var(--Neutral-Placeholder, #bfbfbf);
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  background-color: #f5f7f9;
  border: solid 1px #d9d9d9;
  border-radius: 4px;
  cursor: pointer;

  :global {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  svg {
    color: #bfbfbf;
  }

  &.dayView {
    position: absolute;
    min-width: 1px !important;
  }
}
