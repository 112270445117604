.documents {
  margin: 0;
  max-width: 300px;
  :global {
    .ant-list-item {
      padding: 6px 0;
      border-block-end: 0 solid transparent;

      .ant-list-item-meta {
        align-items: center;
      }

      .ant-list-item-meta-avatar {
        margin-inline-end: 10px !important;
      }

      .ant-list-item-meta-title {
        margin-bottom: 0 !important;
        font-weight: 400;
      }
    }
  }
}
