@import 'src/resource/style/variables';

.wrapper {
  background: #f0f0f0;
}

.tableWrapper {
  padding: 16px;
  background: #fff;
  border-radius: 8px;

  :global {
    table {
      border-collapse: collapse;

      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .ant-pagination.ant-table-pagination {
      padding: 0;
    }

    .ant-table-wrapper .ant-table-thead > tr > th {
      &:first-child {
        border-radius: 8px 0 0 0 !important;
      }

      &:last-child {
        border-radius: 0 8px 0 0 !important;
      }
    }
    .ant-table-row {
      & > td {
        padding: 4px 8px !important;
      }
    }
    .ant-table-row-selected {
      position: relative;

      & > td {
        background: #eff6eb !important;
      }

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // border: 1px solid $primary-color !important;
        border-radius: 8px;
        content: '';
        pointer-events: none;
        opacity: 0;
      }
    }
  }
}

.alert {
  max-width: 1350px;
  margin-bottom: 16px;
  background: #f1f9ec;
  border: 1px solid #c2e3af;
}

.notes {
  margin: 4px 0 8px 0;
  color: #8c8c8c;
  font-size: 12px;
}

.requirements {
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  display: block;
  border-radius: 8px;
}

.text {
    max-width: 460px;
}
