@import 'src/resource/style/variables';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
}

.collapse {
  :global {
    .ant-collapse-expand-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-right: 16px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
      padding-inline-end: 0 !important;
      flex-shrink: 0;
    }

    .ant-collapse-item .ant-collapse-header {
      padding: 14px 9px 14px 16px;
    }

    .ant-collapse-header-text {
      font-weight: 600;
    }
  }
}

.panel {
  margin-bottom: 12px;
  background: #fcfcfc;
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px !important;

  :global {
    .ant-collapse-content-box {
      padding: 0 24px 14px 56px !important;
      font-size: 14px;
    }
  }
}

.wrapper {
  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 70px;
    padding-bottom: 30px;
  }
}
