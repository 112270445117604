@import 'src/resource/style/variables';

.inner {
  min-height: calc(100vh - 64px - 32px);
  padding: 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.closeBtn {
  padding: 0;
  color: #8c8c8c;
}

.status {
  display: block;
  height: 100%;
  margin-left: 10px;
  font-weight: 400;
}

.bottomBtns {
  display: flex;
  justify-content: flex-end;
}

.warning {
  margin-top: -3px;
  display: block;
  color: rgb(245, 34, 45);
  padding-left: 6px;
}