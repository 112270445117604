@import 'src/resource/style/variables';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
}

.wrapper {
  background-color: $bg-color-content;

  .icon {
    margin-left: 8px;
    color: $neutral-secondary;
    font-size: 18px;
    cursor: pointer;
  }
}

.inner {
  min-height: calc(100vh - 64px - 82px);
  padding: 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.form {
  max-width: 556px;
  padding: 24px 24px 24px 24px;
  background: #fcfcfc;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  :global {
    .ant-form-item-label {
      font-weight: 600;
    }
  }
}

.statusBtn {
  margin-top: 16px;
}
