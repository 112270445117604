.clearFilter {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  font-weight: 400;
  font-size: 12px;
  background-color: #fff;
  box-shadow: 0 -8px 16px rgba(0, 0, 0, 0.04);
}

.btn {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-decoration: underline;
  background-color: #fff;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  padding: 0 8px;

  .logoSvg {
    width: 100%;
    max-width: 122px;
    height: 32px;
    object-fit: contain;
  }
}
