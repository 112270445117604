.main {
    padding: 0px 0 14px 0;
    display: flex;
    justify-content: space-between;
}

.badge {
    margin-left: 10px;
}

.divider {
    height: 28px;
}