.layout {
  width: 100%;
  padding: 31px 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  &.marginBottom {
    margin-bottom: 16px;
  }

  h5 {
    margin: 0 0 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }
}

.earning {
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
}
