.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;

  .filter {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    flex-wrap: nowrap;

    > *:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.btn {
  box-shadow: none;
}