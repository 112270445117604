@import 'src/resource/style/variables';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
}

.wrapper {
  padding: 16px 16px;
}

.btn {
  color: $primary-color;
  border: 1px solid $primary-color;
}
