.wrapper {
  :global {
    .ant-select-selector {
      height: 20px !important;
      padding: 0 !important;
      border: none !important;
    }

    .ant-form-item-label {
      padding: 0 !important;
    }

    .ant-select-selection-item {
      font-weight: 600;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 130px;
}

.container {
  width: 100%;
}
