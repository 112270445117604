@import 'src/resource/style/variables';

.wrapper {
  max-width: 556px;
  margin-bottom: 16px;
  padding: 24px;
  background: #fcfcfc;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.row {
  margin-bottom: 8px;
  .inner {
    display: flex;
    align-items: center;
  }

  .input {
    width: 550px;
  }

  .remove {
    margin: 0 -16px 0px 0;
    padding: 0 10px;
    font-size: 16px;

    svg {
      color: $neutral-secondary;
    }
  }

  :global {
    .ant-form-item {
      margin-bottom: 0px;
    }
  }

  &:last-child {
    .remove {
      margin-bottom: 0;
    }

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.badgeSelect {
  :global {
    .ant-select-selector {
      width: 15px !important;
      padding: 0 !important;
      background: transparent !important;
      border: 0px solid transparent !important;
      cursor: pointer !important;
    }
    .ant-select-arrow {
      display: none;
    }

    .ant-select-selection-item {
      text-overflow: initial !important;
    }
  }
}

.badgePadding {
  width: 17px;
}
.checkbox {
  padding-left: 16px;
}
