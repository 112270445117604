.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
}

.wrapper {
  padding: 16px;
}

.btn {
  background: #fff !important;
}

.nested {
  margin-top: 12px;
}

.collapse {
  :global {
    .ant-collapse-expand-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-right: 16px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02);
      padding-inline-end: 0 !important;
    }

    .ant-collapse-header {
      background: #eff6eb;
      border-radius: 6px !important;
    }

    .ant-collapse-item .ant-collapse-header {
      align-items: center;
      padding: 8px 8px 8px 12px;
    }

    .ant-collapse-header-text {
      font-weight: 600;
    }
  }
}

.panel {
  margin-bottom: 12px;

  :global {
    .ant-collapse-content-box {
      padding: 0 0 0px 56px !important;
      font-size: 14px;
    }
  }
}
