.header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;

  h4 {
    margin: 0;
  }

  .control {
    > *:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.btn {
  box-shadow: none;
}
