.input {
  :global {
    .ant-form-item-label label {
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.modal {
  max-width: 456px;
  :global {
    .ant-modal-content {
      padding: 34px 40px;
    }
  }
}

.title {
  margin: 0 0 30px 0 !important;
}

.btn {
  margin-top: 12px;
}
