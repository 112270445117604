.bordered {
  padding: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.modal {
  width: 100% !important;
  max-width: 660px;
  padding: 24px;
  :global {
    .ant-form-item-label > label {
      font-weight: 600;
    }

    .ant-modal-content::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background: #fff;
      border-radius: 8px;
      content: '';
    }
  }
}
