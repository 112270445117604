@import "src/resource/style/variables";

.wrapper {
  position: relative;

  :global {
    .ant-input-prefix {
      margin-inline-end: 8px;
    }
  }

  input {
    font-size: 14px;
    line-height: 22px;
    color: $primary-color;
  }

  .upload {
    right: 0;
    top: 0;
    width: 100%;
    padding: 4px;
    box-sizing: content-box;
    display: flex;
    position: absolute;
    justify-content: right;
    z-index: 1;

    button {
      position: inherit;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
