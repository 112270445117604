.notificationsWrapper {
  display: flex;
  flex-direction: column;
  // width: 100%;
  width: 460px;
  height: fit-content;
  max-height: 80vh;
  padding: 12px;
  background: var(--color-page-background);
  backdrop-filter: blur(30px);

  &.fullWidth {
    width: 100%;
  }

  .notificationsGroupTitle {
    width: 100%;
    margin-bottom: 0;
    color: var(--color-grey-additional);
    text-align: left;
  }

  .notificationsContent {
    flex: 1 1 auto;
    width: 100%;
  }
}

.notificationRow {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
  background: #fcfcfc;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  &:hover {
    background: var(--color-button-third-hover);
  }

  .notificationIcon {
    margin-right: 10px;
    color: var(--color-text-primary);
    font-size: 18px;
  }

  .notificationContent {
    flex: 1 1 auto;
    color: var(--color-text-primary);
  }

  .notificationTime {
    color: var(--color-text-primary);
    font-size: 15px;
  }
}

.notificationsButton {
  padding: 0;
  color: var(--color-gray-1) !important;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: var(--color-main-orange) !important;
  }
}

.badge {
  margin-left: 4px;
}

.tapArea {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
