.panel {
  width: 100%;
  padding: 16px 24px;
  background: #eff6eb;
  border: 1px solid #528a31;
  border-radius: 6px;
}

.title {
  margin-top: 12px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.space {
  gap: 0 !important;
  max-width: 100%;
  margin-bottom: 20px;
  :global {
    .anticon {
      position: relative;
      overflow: hidden;
      color: rgb(97, 162, 58);
      font-size: 16px;
      border-radius: 6px;
    }
  }
}

.induction {
  max-width: 100%;
  height: 40px;
}
