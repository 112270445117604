.wrapper {
  width: 100%;
  max-width: 715px;
  margin: 0 auto;
  padding: 24px 20px;
}

.inner {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 24px;
  background: var(--neutral-neutral-2, #fcfcfc);
  border: 1px solid var(--neutral-stroke, #d9d9d9);
  border-radius: 8px;
  :global {
    .anticon {
      margin-right: 8px;
      font-size: 18px;
    }
  }
}
