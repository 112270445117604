.overlaping {
  margin: 24px 0;
}

.overlapPopup {
  :global {
    .ant-modal-close {
      width: 32px;
      height: 32px;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 100%;
    }
  }
}
