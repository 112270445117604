.calendar {
  padding: 0 16px;
}

.blocked {
  position: absolute;
  left: 0;
  top: 64px;
  width: 100%;
  height: calc(100% - 64px);
  backdrop-filter: blur(3px);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
