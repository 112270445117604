.title {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  h4 {
    margin: 0;
    white-space: nowrap;
  }

  .tabs {
    margin: -2px 0 -2px 16px;
    display: block;
    white-space: nowrap;
  }
}
