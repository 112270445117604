.icon {
  color: rgba(0, 0, 0, 0.45);
}

.status {
  text-transform: capitalize;
}

:global {
  .ant-table-wrapper {
    .ant-table-tbody > tr > td {
      padding: 12px;
      border-bottom: none;
    }

    .ant-table-thead > tr > th:first-child,
    .ant-table-tbody > tr > td:first-child {
      padding-left: 16px !important;
    }

    .ant-table-thead > tr > th:last-child,
    .ant-table-tbody > tr > td:last-child {
      padding-right: 16px !important;
    }

    .ant-table-thead > tr > th {
      padding: 13px 16px;
      background: #eff6eb;
      border-radius: 0 !important;

      &:before {
        background-color: #e1e7dd;
      }
    }
  }

  .ant-pagination {
    padding-right: 16px;
  }
}

.actions {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  justify-content: center;
  width: 100%;

  svg {
    color: rgba(0, 0, 0, 0.45);
  }
}
