.modal {
  width: 100% !important;
  max-width: calc(100% - 48px);
  margin: 24px auto;
  :global {
    .ant-modal-close {
      display: none;
    }
  }
}

.closeBtn {
  padding: 0;
  color: #8c8c8c;
}
