@import 'src/resource/style/variables';

.wrapper {
  .fileName {
    padding-left: 12px;
    color: #1f1f1f;
    font-weight: 400;
  }

  .fileIcon {
    color: $primary-color;
    font-size: 24px;
  }

  .fileList {
    margin-bottom: 10px;
  }

  .fileRow {
    padding: 5px 0;
    border: none;
  }

  .fileItem {
    display: flex;
    align-items: center;

    &.fileError {
      .fileName {
        color: #ff4d4f;
      }
    }
  }

  :global {
    .ant-upload-drag {
      background: #ffffff;
      border: 1px dashed #d9d9d9;
    }

    .ant-list-item-action li {
      padding-right: 0 !important;
    }
  }

  .dragger {
    background: #000;
  }
}
