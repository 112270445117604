@import "src/resource/style/variables";

.filterMenu {
  > *:not(:last-child) {
    margin-bottom: 16px !important;
  }

  :global {
    .ant-menu-title-content {
      cursor: default;
      padding: 1px 0;
      color: $primary-text !important;
      height: auto;
      line-height: normal;
      font-size: 14px;
    }

    .ant-menu-submenu-title {
      padding: 0 16px !important;
      margin: 0;
      border-radius: 0;
      width: 100%;
      background-color: #F5F7F9;
      height: 38px !important;
      line-height: 38px !important;

      .ant-menu-title-content {
        font-weight: 600;
      }
    }

    .ant-menu-sub {
      .ant-menu-item {
        max-width: 223px;
        font-weight: 400;
        height: auto;
        margin: 0;
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        background-color: #fff;

        &:hover,
        &:active,
        .ant-menu-item-selected {
          background-color: #fff !important;
        }

        &:first-child {
          padding-top: 6px;
        }

        &:last-child {
          padding-bottom: 6px;
        }
      }

      .ant-radio-wrapper,
      .ant-checkbox-wrapper {
        width: 100%;
        padding: 2px 16px;
        box-sizing: border-box;
      }
    }

    .ant-menu-submenu-arrow {
      color: $primary-color;
    }

    input {
      font-weight: 400;
    }
  }
}
