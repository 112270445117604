.collapse {
  :global {
    .ant-collapse-expand-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-right: 16px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02);
      padding-inline-end: 0 !important;
    }

    .ant-collapse-item .ant-collapse-header {
      align-items: center;
      height: 76px;
      padding: 0 8px 0 12px !important;
    }

    .ant-collapse-header-text {
      padding-right: 24px;
      font-weight: 600;
      font-size: 20px;
      margin-inline-end: 0 !important;
    }

    .ant-collapse-item {
      margin-bottom: 24px;
      background: var(--neutral-neutral-2, #fcfcfc);
      border: 1px solid var(--neutral-stroke, #d9d9d9);
      border-radius: 8px !important;

      .ant-collapse-content-box {
        padding: 6px 22px 0px 22px !important;
        font-size: 14px;
      }

      .ant-collapse-extra {
        width: calc(100% - 225px);
      }

      &.ant-collapse-item-active {
        .ant-collapse-extra {
          display: none;
        }
      }
    }
  }

  &.extraMarging {
    :global {
      .ant-collapse-extra {
        width: calc(100% - 250px);
      }
    }
  }
}
