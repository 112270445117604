.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
}

.wrapper {
  width: 100%;
}
