.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.disabledApply {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
}
