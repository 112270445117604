@import "src/resource/style/variables";

.modal {
  min-width: 696px;

  .content {
    svg {
      margin-bottom: 36px;
    }

    h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: $primary-text;
    }

    span {
      margin-top: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $neutral-secondary;
    }
  }

  :global {
    .anticon {
      display: none;
    }

    .ant-modal-content {
      min-height: 492px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      .ant-modal-confirm-content {
        text-align: center;
        width: 376px;
      }

      .ant-modal-confirm-btns {
        margin-top: 40px;
      }

      .ant-modal-confirm-btns, button {
        width: 376px;
        text-align: center;
      }
    }
  }
}
