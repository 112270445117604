.wrapper {
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  overflow: auto;

  &.clearFilters {
    height: calc(100% - 78px);
  }

  :global {
    .ant-menu-root .ant-menu-submenu .ant-menu-sub {
      overflow: auto;
      max-height: 220px;
    }
  }
}

.disabledBlock {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.8;
}
