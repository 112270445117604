.tabs {
    :global {
        .ant-tabs-nav {
            margin-bottom: 0;
            z-index: 1;

            &:before {
                display: none;
            }
        }

        .ant-tabs-nav-wrap {
          overflow: visible !important;
        }

        .ant-tabs-tabpane {
          border: 1px solid #D9D9D9;
          background: #fff;
          padding: 24px;
          border-radius: 0 8px 8px;
        }

        .ant-tabs-nav-list {
            .ant-tabs-tab {
                border-color: #D9D9D9;
                border-bottom: 1px solid transparent;
                position: relative;
                padding: 6px 15px;

                &::before {
                    position: absolute;
                    content: '';
                    bottom: -10px;
                    top: calc(100% - 1px);
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 11px;
                    background: #fff;
                    opacity: 0;
                    transition: all .3s;
                }

                .ant-tabs-tab-btn {
                    font-size: 16px;
                }

                &:not(.ant-tabs-tab-active) {
                    background: #F5F7F9;
                }

                &.ant-tabs-tab-active {
                    border-bottom: 1px solid transparent !important;

                    &::before {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
