.icon {
  color: rgba(0, 0, 0, 0.45);
}

.status {
  text-transform: capitalize;
}

.btn {
  padding: 0;
  height: auto;
}


