@import 'src/resource/style/variables';

.header {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  span:last-child {
    background-color: $bg-color-content;
  }

  span {
    width: 32px;
    height: 32px;
    padding: 9px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;

    svg {
      color: #8c8c8c;
    }

    &:hover {
      background-color: #dbdbdb;
    }
  }
}

.alert,
.loading {
  margin: 16px 0 16px -6px;
}

.loading {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
}

.hovered {
  background: var(--primary-highlight, #eff6eb);
  border: 1px solid var(--primary-primary-green, #61a23a);
  border-radius: 4px;
}

.time {
  align-items: flex-end;
}

.shift {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: block;
  box-sizing: border-box;
  height: 334px;
  padding: 11px 24px 24px;
  overflow: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  transform: translate(-999999px, -999999px);

  .content {
    padding-top: 11px;

    .row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;
      line-height: 22px;

      > :first-child {
        font-weight: 600;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.options {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  width: min-content;
  align-items: start;
  padding: 4px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08);

  :global {
    .ant-btn {
      text-align: left;
    }
  }
}
