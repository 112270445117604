.changePositionTextarea {
  :global {
    .ant-modal-confirm-content {
      max-width: 100% !important;
      width: 100%;
      margin-top: 16px !important;
      margin-inline-start: 0 !important;
    }
  }
}
