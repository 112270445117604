.wrapper {
  width: 100%;
  max-width: 556px;
  padding: 24px;
  background: #eff6eb;
  border: 1px solid #528a31;
  border-radius: 8px;

  .title {
    margin: 0 0 16px 0;
  }

  .btnWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;

    :global {
      .ant-btn {
        margin-left: 16px;
      }
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
}
