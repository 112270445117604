.content {
  padding: 16px 16px 0 16px;
}

.loading {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}