@import 'src/resource/style/variables';

.wrapper {
  width: 100%;
  max-width: 1150px;
}

.orientations {
  .item {
    align-items: flex-start;
    margin-bottom: 16px;
    padding: 2px 0px 2px 12px;
    padding: 10px 0 16px 10px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s;
    border-block-end: 1px solid #d9d9d9 !important;

    &.active {
      background: #eff6eb;
      border: 1px solid #528a31 !important;
    }

    &:hover {
      border: 1px solid #528a31 !important;
    }

    :global {
      .ant-list-item-meta-title {
        font-weight: 600;
      }


      .ant-list-item-meta-content .ant-list-item-meta-description {
        margin-top: 5px;
        color: #1f1f1f;

        .ant-btn {
          height: auto;
          padding: 0px;
          color: $primary-color;
        }
      }
    }
  }

  .btn {
    padding-right: 4px;
    padding-left: 4px;
  }

  .icon {
    color: #8c8c8c;
  }
}

.text {
  margin-top: 4px;
  margin-bottom: 20px !important;
  color: #8c8c8c;
  font-size: 12px;
}

.createBtn {
  color: $primary-color;
  border: 1px solid $primary-color;
}

.unlink {
  display: flex;
  justify-content: flex-end;
  margin-top: 3px;

  button {
    padding: 0;
    height: 24px;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}