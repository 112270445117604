@import "src/resource/style/variables";

.radio {
  background-color: $neutral-separator-lines;
  padding: 2px;
  border-radius: 6px;
  overflow: hidden;

  button:not(:last-child) {
    margin-right: 2px;
  }

  button:not(.active) {
    &:hover,
    &:active,
    &:focus {
      background-color: transparent !important;
      color: $primary-color !important;
    }
  }

  .active {
    color: $primary-color !important;
    background-color: #fff !important;
  }
}
