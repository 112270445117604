.modal {
    max-width: 928px;
    width: 100% !important;
}

.table {
    margin-top: 24px;
    :global {
        .ant-pagination {
            display: none;
        }
        thead .ant-table-cell.ant-table-selection-column {
            background: #eff6eb;
            border-start-start-radius: 0 !important;
        }
    }
}

.showMore {
    margin: 0 auto;
    display: block;
}

.search {
    max-width: 240px;
}

.tooltipe {
    :global {
        .ant-tooltip-inner {
            max-height: 300px;
            overflow-y: auto;
        }
    }
}

.bullet {
    :global {
        .ant-badge-status-text {
            color: #fff !important;
        }
    }
}
