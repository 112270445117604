@import 'src/resource/style/variables';

:root {
  --empty-shift-size: 1;
}

.wrapper {
  position: relative;
  display: block;
  box-sizing: border-box;
  min-width: 100%;
  min-height: calc(100vh - 267px);
  // padding: 60px 0 0 211px;
  padding: 60px 0 0 260px;
  overflow: hidden;
  border-top: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9;

  .search {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    // width: 211px;
    width: 260px;
    height: 60px;
    padding: 0 20px;
    background-color: #fff;
    border-right: solid 1px #d9d9d9;
    border-bottom: solid 1px #d9d9d9;
  }

  .timeline {
    position: absolute;
    top: 0;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    height: 60px;
    overflow: auto;
  }

  .list {
    position: absolute;
    top: 60px;
    left: 0;
    // width: 211px;
    width: 260px;
  
    overflow: auto;

    .user {
      // width: 211px;
      width: 260px;
      min-height: 96px;
      font-size: 13px;
      line-height: 19px;
      box-shadow: 0 0 0 0.5px #d9d9d9;
      display: flex;
      align-items: center;
      overflow: auto;

      &:last-child {
        margin-bottom: 2px;
      }

      .wrp {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 6px 12px;
        text-align: right;
        justify-content: flex-end;
        width: 100%;
      }
 
      svg {
        color: rgba(0, 0, 0, 0.45);
        font-size: 24px;
        margin-right: 12px;
      }

      .name {
        overflow: hidden;
      }

      .location {
        white-space: initial;
      }
      .location,
      .time {
        font-size: 11px;
        line-height: 18px;
      }

      .time {
        color: $neutral-secondary;
      }

      .options {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 20px;
        height: 100%;
        padding-right: 4px;
        cursor: pointer;

        svg {
          font-size: 16px;
        }
      }
    }
  }

  .content {
    position: relative;
    box-sizing: border-box;
    min-width: 100%;
    height: 100%;
    overflow: auto;
    box-shadow: 0 0 0 1px #d9d9d9;

    .tableShadow {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: none;
      width: 80px;
      height: 100%;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      box-shadow: 100px 0 0 100px #fff;
      content: '';
      pointer-events: none;
    }
  }

  table {
    position: relative;
    z-index: 1;
    width: 100%;
    min-width: 960px;

    td {
      position: relative;
      vertical-align: top;
      box-shadow: 0 0 0 0.5px #d9d9d9;

      .day {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        box-sizing: border-box;
        // min-width: 90px;
        min-width: 150px;
        min-height: 100px;
        padding: 8px 0 30px 0;

        &.padding {
          padding: 8px 8px 30px 8px;
        }

        > * {
          min-width: 134px;
          min-height: calc(58px * var(--empty-shift-size) + (var(--empty-shift-size) * 4px) - 4px);
        }

        .shifts {
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          width: 100%;
          padding: 8px;
          font-size: 12px;
          background-color: #f0f0f0;
          border: dashed 1px #bfbfbf;
          border-radius: 4px;
          cursor: pointer;
        }
      }

      .create {
        position: absolute;
        bottom: 7px;
        left: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 14px);
        height: 16px;
        background-color: #f5f7f9;
        border: solid 1px #d9d9d9;
        border-radius: 2px;
        cursor: pointer;
        opacity: 0;
        transition: 0.3s;

        svg {
          color: #bfbfbf;
          font-size: 12px;
        }
      }

      &:hover {
        .create {
          opacity: 1;
        }
      }
    }
  }

  &.applicant {
    table tr td .day > :not(.shifts) {
      min-height: 74px;
    }
  }

  &.shadow {
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      width: 80px;
      height: 100%;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      box-shadow: 100px 0 0 100px #fff;
      content: '';
      pointer-events: none;
    }

    .tableShadow {
      display: block;
    }
  }
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.05);
}

.variantWrap {
  position: relative;
  width: 100%;

  &.isAppplicant {
    position: absolute;
  }
}

.remainder {
  color: #ff4d4f;
}
