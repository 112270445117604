@import "src/resource/style/variables";

.wrapper {
  max-width: 556px;
  background: #FCFCFC;
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 24px 24px 0 24px;

  :global {
    .ant-alert {
      margin-bottom: 16px;
    }

    .ant-form-item-label {
      font-weight: 600;
    }
  }
}

.loading {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
