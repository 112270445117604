@import "src/resource/style/variables";

$tabHeight: 40px;
$tabMargin: 25px;

.tabs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, calc(100vw / 10)));
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  height: $tabHeight;
  margin: $tabMargin 8px $tabMargin 3vw;

  .item {
    height: $tabHeight;
    padding: 8px 0;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: $white;
    position: relative;
    z-index: 1;
    color: $neutral-secondary;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -8px;
      width: 8px;
      height: 1px;
      background-color: $white;
    }

    .icon {
      display: flex;
      justify-content: center;
      width: 24px;
      height: 24px;
      background-color: $neutral-separator-lines;
      border-radius: 50%;
      margin-right: 8px;
      line-height: 24px;
    }

    &.active {
      color: $primary-text;

      .icon {
        color: $white;
        background-color: $primary-color;
      }
    }

    .name {
      display: flex;
      position: relative;
      height: 24px;
      line-height: 24px;
      align-content: center;
    }

    &:not(:last-child) {
      .name:after {
        z-index: 0;
        content: "";
        position: absolute;
        left: calc(100% + 16px);
        top: 50%;
        width: 100%;
        height: 1px;
        background-color: $neutral-separator-lines;
      }
    }
  }
}

.content {
  display: grid;
  grid-auto-rows: auto 1fr auto;
  position: relative;
  min-height: calc(100vh - ($tabHeight + $tabMargin * 2));
  justify-content: center;
  align-items: flex-start;

  .page {
    width: 696px;
    min-height: 492px;
    box-sizing: border-box;
    background: #FCFCFC;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 32px 40px 80px;
    display: flex;
    justify-content: center;

    :global {
      .ant-form {
        > *:last-child {
          display: flex;
          flex-wrap: nowrap;
          position: absolute;
          bottom: -64px;
          right: 0;
          margin-right: 0;

          > * {
            margin-left: 16px;
          }
        }
      }
    }

    > .disabled {
      display: none !important;
    }
  }

  .account {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 32px 0 37px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    a {
      margin-left: 4px;
    }
  }
}
