.form {
  width: calc(375px + 80px);
  padding: 32px 40px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin: 0 auto 24px;
}

.text {
  font-size: 16px;
  margin-bottom: 27px;
  display: block;
}

.logo {
  width: 100%;
  object-fit: contain;
  margin: 0 auto;
  display: block;
}

.btn {
  margin-top: 12px;
}
