.applySelected {
  position: absolute;
  top: -39.5px;
  right: 8px;
  z-index: 2;
}

.shiftColumn {
  min-width: 128px;
}

.roleColumn {
  min-width: 120px;
}

.dateTime {
  display: flex;
  align-items: center;
  width: 100%;
}

.multiDayIcon {
  margin-left: 8px;
}

.multiDayRow {
  border-bottom: 1px solid #fff;
  :global {
    svg path {
      fill: #fff;
    }
  }
}

.link {
  margin-right: 8px;
  padding-right: 8px;
  border-right: 1px solid #d9d9d9;
}
