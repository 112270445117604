@import 'src/resource/style/variables';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 70px;
  padding-bottom: 30px;
}

.extra {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
}

.avatar {
  color: #faad14;
  font-size: 18px;
  margin-top: 3px;
}

.date,
.time {
  color: #1f1f1f;
  :global {
    .anticon {
      padding-right: 7px;
      color: #727272;
      font-size: 16px;
    }
  }
}
.removeBtn {
  padding: 4px;
  :global {
    .anticon {
      color: #727272;
      font-size: 20px;
    }
  }
}

.badge {
  font-weight: 400;
  font-size: 12px;
  display: block;
  margin-top: -2px;
}

.item {
  margin-bottom: 8px;
  border: 1px solid var(--neutral-stroke, #d9d9d9);
  border-radius: 8px;
  border-block-end: 1px solid #d9d9d9 !important;
  :global {
    .ant-list-item-meta-title {
      margin: 0 !important;
    }

    .ant-list-item-action {
      position: relative;
      &::before {
        position: absolute;
        top: -8px;
        right: 0;
        left: 0;
        width: 100%;
        width: 100%;
        height: 1px;
        background: #f5f7f9;
        content: '';
      }
    }
  }
}
