@import "src/resource/style/variables";

.wrapper {
  .header {
    padding: 16px;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.collapseWrapper {
  border-top: solid 1px $bg-color-content;
  padding: 16px 16px 4px 16px;
}
