.title {
  display: block;
  margin: 0 0 20px 0 !important;
  font-size: 16px;
}

.secondary {
  letter-spacing: -0.5px;
}

.password {
  margin-bottom: 19px;
}

.btn {
  margin-top: 12px;
}

.form {
  width: calc(376px + 80px);
  margin: 24px auto;
  padding: 32px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}

.email {
  margin-top: 32px;
  margin-bottom: 40px;
}

.logo {
  display: block;
  width: 100%;
  max-width: 240px;
  margin: 0 auto;
  object-fit: contain;
}
