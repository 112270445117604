@import "../../../../resource/style/variables.scss";

.wrapper {
    max-width: 274px;
    width: 100%;
}

.card {
    min-height: 274px;
    max-width: 275px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.icon {
    color: $primary-color;
    margin-right: 11px;
    font-size: 12px;
}

.item {
    margin-bottom: 10px;
}