@import 'src/resource/style/variables';

.select {
  :global {
    .ant-select-selector {
      padding-left: 30px !important;
    }

    .ant-select-selection-search-input {
      padding-left: 18px !important;
    }

    .ant-select-arrow {
      left: 10px;
    }
  }
}
