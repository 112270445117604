.form {
    background: #FCFCFC;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 24px;
    max-width: 560px;
    width: 100%;

    :global {
        .ant-form-item-label {
            font-weight: 600;
        }
    }

    .title {
        margin: 0 0 16px 0;
    }

    .input {
        margin-bottom: 24px;
    }
}

.wrapper {
    width: 100%;
}