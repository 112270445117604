@import 'src/resource/style/variables';

.wrapper {
  max-width: 1230px;
  margin: 0 auto;
}

.inner {
  min-height: calc(100vh - 146px);
  padding: 24px;
  background: #fff;
  border: 1px solid var(--neutral-stroke, #d9d9d9);
  border-radius: 8px;
}

.form {
  max-width: 556px;
  padding: 24px 24px 24px 24px;
  background: #fcfcfc;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  :global {
    .ant-form-item-label {
      font-weight: 600;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}
