@import 'src/resource/style/variables';

.wrapper {
  background-color: $bg-color-content;

  .icon {
    margin-left: 8px;
    color: $neutral-secondary;
    font-size: 18px;
    cursor: pointer;
  }
}

.inner {
  min-height: calc(100vh - 64px - 82px);
  padding: 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.form {
  max-width: 556px;
  padding: 24px 24px 0 24px;
  background: #fcfcfc;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  :global {
    .ant-form-item-label {
      font-weight: 600;
    }
  }
}
