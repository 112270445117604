.wrapper {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;

  button:not(:last-child) {
    margin-right: 16px;
  }
}

.arrowBack {
  color: #8c8c8c;
}

.disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  &:hover {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
}
