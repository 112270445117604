@import "./src/resource/style/variables";
@import "src/resource/font/stylesheet";

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  overflow-wrap: anywhere;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Arial', serif;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration: none;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

html, body {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

.ant-pro-card {
  .ant-pro-card-body {
    padding: 0;
    margin: 0;

    .pro-table-checkbox-text {
      display: none;
    }

    .ant-pro-table-list-toolbar-container {
      padding: 16px;
    }

    .ant-pro-table-list-toolbar-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }
}

.ant-pro-table-column-setting-overlay {
  .pro-table-checkbox {
    display: none;
  }
}

#root {
  min-height: 100vh;
  min-width: 720px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  .ant-app {
    width: 100%;
  }

  > * {
    max-width: 2560px;
    box-shadow: 0 0 50px #eee;
  }
}

.ant-input-prefix {
  svg {
    color: $neutral-secondary;
  }
}

.ant-pro-table-list-toolbar-right {
  margin-left: 16px;
  gap: 16px;
}

.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #eee;
}

.ant-badge-status-text {
  text-transform: capitalize;
}

.ant-badge {
  white-space: nowrap;
}

.ant-form-item-label {
  font-weight: 600;
}

.ant-select {
  .ant-select-arrow {
    inset-inline-end: 9px;
  }
}

.ant-pro-table-list-toolbar {
  .ant-pro-table-list-toolbar-container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr auto;

    .ant-pro-table-list-toolbar-left,
    .ant-pro-table-list-toolbar-title {
      width: 100%;
      max-width: 100%;
    }
  }
}

.ant-collapse-content-box .ant-table-wrapper {
  border-top: none !important;
  padding: 0 !important;
}

.ant-pagination.ant-table-pagination {
  padding: 0 16px 16px;
}

.ant-picker-calendar {
  .ant-picker-calendar-date {
    padding-top: 0 !important;

    .ant-picker-calendar-date-content {
      height: 100px;

      ul > li {
        height: 20px;

        > span {
          height: 20px;
          line-height: 18px;
        }
      }
    }
  }
}

.ant-pro-table-column-setting-list-item-title {
  white-space: nowrap;
}
