.disabledSelect {
  margin-bottom: 12px;
  :global {
    .ant-select-arrow {
      display: none;
    }

    .ant-select-selector {
      background: transparent !important;
    }
  }
}
