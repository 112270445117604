.table {
    :global {
      .ant-table-tbody > tr > td {
            border-bottom: 1px solid #EFF6EB !important;
        }
        .ant-table-container .ant-table-container {
            margin-top: 4px;
            margin-bottom: 3px;
        }


        .ant-table-expanded-row.ant-table-expanded-row-level-1 {
            background: var(--Neutral-neutral-2, #FCFCFC);
        }

        .ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-cell{
            background: initial !important;
        }

.ant-table-thead {
    background: var(--Neutral-Table-BG, #F0F0F0);

}

    }
}