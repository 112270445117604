.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 35px;
}

.modal {
  :global {
    .ant-modal-footer {
      display: none;
    }
  }
}
