.count {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 22px;
  color: var(--primary-primary-green, #61a23a);
  font-size: 10px;
  background: var(--primary-primary-2, #d9e9d0);
  border: 1px solid var(--primary-primary-green, #61a23a);

  border-radius: 4px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
}
