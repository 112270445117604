@import "src/resource/style/variables";

.wrapper {
  background-color: $bg-color-content;

  .tabs {
    margin-top: -8px;
  }

  :global {
    .ant-tabs-tabpane {
      min-height: calc(100vh - 175px);
    }
  }
}
