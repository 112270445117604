@import "src/resource/style/variables";

.wrapper {
  position: relative;

  :global {
    .ant-table-wrapper {
      border-top: solid 1px $bg-color-content;
      padding: 16px;

      .ant-pagination.ant-table-pagination {
        padding: 0;
      }

      .ant-table-thead > tr > th {
        &:first-child {
          border-radius: 6px 0 0 0 !important;
        }

        &:last-child {
          border-radius: 0 6px 0 0 !important;
        }
      }
    }
  }
}
