.shifts {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  font-size: 12px;
  background-color: #f0f0f0;
  border: dashed 1px #bfbfbf;
  border-radius: 4px;
  cursor: pointer;
}
