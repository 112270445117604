.wrapper {
  position: relative;

  > .loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,.4);
  }

  > .menu {
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
    }
  }
}

.menu {
  :global {
    .ant-menu-item {
      margin-top: 0;
      padding-bottom: 10px;
    }
  }

  li label span {
    text-transform: capitalize;
    background-color: red;
  }
}

.select {
  padding-left: 16px;
  width: calc(100% - 16px);
  margin-top: 5px;

  :global {
    .ant-select-selection-overflow {
      max-height: 112px;
      overflow: hidden;
    }
  }
}

.popup {
  max-width: 191px !important;
  min-width: 191px !important;
}
