.icons {
  display: flex;
  align-items: center;
  height: 16px;
}
.icon {
  margin-left: 4px;
  color: #00000073;
}

.status {
  :global {
    .ant-badge-status-dot {
      width: 12px !important;
      height: 12px !important;
    }
  }
}
