@import 'src/resource/style/variables';

.col {
  display: flex;
  height: 100%;
  min-width: 90px;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 0 0 .5px #d9d9d9;
  position: relative;
  padding: 0 6px;

  .active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: $primary-color;
  }
}
