@import "src/resource/style/variables";

.loading {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  background-color: $bg-color-content
}
