@import 'src/resource/style/variables';

.inner {
  min-height: calc(100vh - 64px - 32px);
  padding: 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.wrapper {
  background-color: $bg-color-content;
}

.status {
  display: block;
  height: 100%;
  margin-left: 10px;
  font-weight: 400;
}

.alert {
  max-width: max-content;
}
